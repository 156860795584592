export const config = {
  title: (custom: string) => `${custom} - Clinique Cursos, Excelência na formação em saúde`,
  url: process.env.URL as string,
  dash_shop: process.env.URL_DASH_SHOP as string,
  dash_login: process.env.URL_DASH_LOGIN as string,
  dash_cadastro: process.env.URL_DASH_CADASTRO as string,
  termo_uso: process.env.URL_DASH_TERMO_USO as string,
  politica_privacidade: process.env.URL_DASH_POLITICA_PRIVACIDADE as string,
  apple_app: "https://apps.apple.com/us/app/shein-shopping-online/id878577184",
  google_app: "https://play.google.com/store/apps/details?id=com.zzkko",
  emptyImage: "https://aule-files.sfo2.cdn.digitaloceanspaces.com/system-defaults/lesson-empty.png",
  contentChannelHome: process.env.CONTENT_CHANNEL_HOME as string,
  contentChannelBlog: process.env.CONTENT_CHANNEL_BLOG as string,
  instagramToken:
    "IGQWRPcTRuQ3VSZAjNrTU1paE00QXJJbTE0T3VMMUQ5V3dTWGtsX0x3WF9tUDBicTNDRVQ5eldYZAVVWX2pxaUJrcy1vdjJLLXU2aVBsNWgxMnAyQk1qY0FyWVA5a1VVZA1VOY0MteDN6a2NocTB5eVBScjBYYnJfU3cZD",
  social: {
    whatsapp: " https://wa.me/5534999999999?text=Ol%C3%A1%2C+quero+receber+orienta%C3%A7%C3%B5es",
    facebook: "https://play.google.com/store/apps/details?id=com.zzkko",
    twiter: "https://play.google.com/store/apps/details?id=com.zzkko",
    instagram: "https://play.google.com/store/apps/details?id=com.zzkko",
    youtube: "https://play.google.com/store/apps/details?id=com.zzkko",
    tiktok: "https://play.google.com/store/apps/details?id=com.zzkko",
    linkdin: "https://play.google.com/store/apps/details?id=com.zzkko",
  },
  contactInfo: [
    {
      title: "Telefone",
      contact: "(34) 99999-9999",
      icon: "feather-headphones",
    },
    {
      title: "E-mail",
      contact: "suporte@cliniquecursos.com.br",
      link: "mailto:suporte@cliniquecursos.com.br",
      icon: "feather-mail",
    },
    {
      title: "Whatsapp",
      contact: "+55 34 999999999",
      link: "https://wa.me/5534999999999",
      icon: "feather-message-circle",
    },
  ],
  api: {
    domaindId: "eWwrHagXGkC5EZSqNRvUkA==",
    token: "eWwrHagXGkC5EZSqNRvUkA==",
    endPoint: {
      content: process.env.API_CONTENT as string,
      course: process.env.API_COURSE as string,
      shop: process.env.API_SHOP as string,
      web: process.env.API_WEB as string,
    },
  },
};
