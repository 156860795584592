"use client";

import { config } from "@/config/config";
import axios from "axios";
import Image from "next/image";
import Link from "next/link";

import { useEffect, useState } from "react";

type Props = {};

const Instagram: React.FC<Props> = (props) => {
  const [feedInstagram, setFeedInstagram] = useState<InstagramDto>();

  useEffect(() => {
    const fetch = async () => {
      try {
        const axiosInstance = axios.create({
          baseURL: "https://graph.instagram.com/",
          headers: {
            "Content-Type": "application/json",
          },
        });

        const response = await axiosInstance.get(`me/media?fields=id,media_url,caption,permalink&access_token=${config.instagramToken}&limit=6`);

        setFeedInstagram(response.data as InstagramDto);
      } catch (ex) {}
    };
    fetch();
  }, []);

  return (
    <div className="mt-5">
      <div className="container">
        <Link href="https://www.instagram.com/cliniquecursos/" target="_blank">
          @CliniqueCursos
        </Link>
      </div>
      <div className="container-fluid">
        <div className="row g-3">
          {feedInstagram &&
            feedInstagram.data.map((item, index) => (
              <div className="col-lg-2 col-md-4 col-sm-6 col-6" key={index}>
                <div className="instagram-grid">
                  <Link href={item.permalink} target="_blank">
                    <Image src={item.media_url} width={384} height={431} alt={item.caption} />
                    <span className="user-info">
                      <span className="icon">
                        <i className="icon-instagram"></i>
                      </span>
                      <span className="user-name">@ClinqueCursos</span>
                    </span>
                  </Link>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Instagram;
