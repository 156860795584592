"use client";

import { ShopApi } from "@/api/shop";
import { config } from "@/config/config";
import { Facebook, Instagram as InstagramIco, Linkedin, Twitter } from "lucide-react";
import Image from "next/image";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import logo from "../../../public/images/logo/Logo_Horizontal_Colorido.png";
import Navigation from "./navigation";

type Props = {};

const HeaderSeven: React.FC<Props> = (props) => {
  const [search, setSearch] = useState(true);
  const [mobile, setMobile] = useState(true);
  const [productList, setProductList] = useState<ProductShopListDto[]>([]);

  useEffect(() => {
    const fetch = async () => {
      try {
        await ShopApi.getProductShopList({
          limit: 4,
          fixed: true,
        }).then((ret) => {
          setProductList(ret.result);
        });
      } catch (ex) {}
    };
    fetch();
  }, []);

  return (
    <>
      <div className="rbt-header-top rbt-header-top-1 variation-height-50 header-space-betwween bg-color-white border-top-bar-primary-color rbt-border-bottom d-none d-xl-block">
        <div className="container">
          <div className="rbt-header-sec-col float-end rbt-header-left">
            <div className="rbt-header-content">
              <div className="header-info">
                <ul className="social-icon social-default icon-naked">
                  <li>
                    <a href={config.social.facebook} target="_blank">
                      <Facebook size={15} />
                    </a>
                  </li>
                  <li>
                    <a href={config.social.twiter} target="_blank">
                      <Twitter size={15} />
                    </a>
                  </li>
                  <li>
                    <a href={config.social.instagram} target="_blank">
                      <InstagramIco size={15} />
                    </a>
                  </li>
                  <li>
                    <a href={config.social.linkdin} target="_blank">
                      <Linkedin size={15} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="rbt-header-wrapper header-not-transparent header-sticky">
        <div className="container">
          <div className="mainbar-row rbt-navigation-end align-items-center">
            <div className="header-left rbt-header-content">
              <div className="header-info">
                <div className="logo">
                  <Link href="/">
                    <Image src={logo} width={152} height={50} priority={true} alt="Education Logo Images" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="rbt-main-navigation d-none d-xl-block">
              <Navigation />
            </div>
            <div className="header-right gap-5">
              <ul className="quick-access">
                <li className="access-icon">
                  <Link className={`search-trigger-active rbt-round-btn ${search ? "" : "open"}`} href="#" onClick={() => setSearch(!search)}>
                    <i className="feather-search"></i>
                  </Link>
                </li>
              </ul>

              <div className="rbt-btn-wrapper d-none d-xl-block">
                <Link className="rbt-btn btn-small btn-secondary radius-round" href={config.dash_login}>
                  Entrar
                </Link>
              </div>

              <div className="mobile-menu-bar d-block d-xl-none">
                <div className="hamberger">
                  <button className="hamberger-button rbt-round-btn" onClick={() => setMobile(!mobile)}>
                    <i className="feather-menu"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className={`rbt-search-dropdown ${!search ? "active" : ""}`}>
            <div className="wrapper">
              <div className="row">
                <div className="col-lg-12">
                  <form action={config.dash_shop} method="get">
                    <input type="text" placeholder="Busque por nossos cursos" id="busca" name="busca" />
                    <div className="submit-btn">
                      <button type="submit" className="rbt-btn btn-small btn-secondary radius-round">
                        Buscar
                      </button>
                    </div>
                  </form>
                </div>
              </div>

              <div className="rbt-separator-mid">
                <hr className="rbt-separator m-0" />
              </div>

              <div className="row g-4 pt--30 pb--60">
                <div className="col-lg-12">
                  <div className="section-title">
                    <h5 className="rbt-title-style-2">Destaques</h5>
                  </div>
                </div>
                {productList.slice(0, 4).map((item, index) => (
                  <div className="col-lg-3 col-md-4 col-sm-6 col-12" key={index}>
                    <div className="rbt-card variation-01 rbt-hover">
                      <div className="rbt-card-img">
                        <Link href={`${config.dash_shop}detalhe/${item.id}`} target="_blank">
                          <Image src={item.thumbnail} width={186} height={128} alt="Card image" />
                        </Link>
                      </div>
                      <div className="rbt-card-body">
                        <h5 className="rbt-card-title">
                          <Link href={`${config.dash_shop}detalhe/${item.id}`} target="_blank">
                            {item.name}
                          </Link>
                        </h5>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderSeven;
