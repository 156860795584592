import { config } from "@/config/config";
import { Api, ApiClass } from "./api";

class ShopApiClass extends ApiClass {
  private instance: ApiClass;
  private baseUrl: string;

  constructor(url: string) {
    super();
    this.instance = Api;
    this.baseUrl = url;
  }

  private obj = () => {
    this.instance.axios.defaults.baseURL = this.baseUrl;
    return this.instance.axios;
  };

  async getProductShopList(params: ProductShopListSearchParams): Promise<PaginationProps<ProductShopListDto[]>> {
    const ret = await this.obj().get(`product/public/shop-list`, { params });
    return {
      result: ret.data,
      _meta: Api.meta(ret),
    };
  }
}

const ShopApi = new ShopApiClass(config.api.endPoint.shop);

export { ShopApi, ShopApiClass };
