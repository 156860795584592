import { WebApi } from "@/api/web";
import { ErrorMessage, Field, Formik } from "formik";
import * as Yup from "yup";

const Newsletter = () => {
  const schema = Yup.object().shape({
    email: Yup.string().required("Informe o e-mail.").email("Informe um e-mail válido."),
  });

  return (
    <div className="rbt-newsletter-area bg-color-white rbt-section-gap">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title text-center">
              <h2 className="title">
                Assine nossa newsletter para <br /> ficar por dentro de todas as novidades
              </h2>
              <Formik
                initialValues={{ email: "" }}
                onSubmit={async (values, actions) => {
                  WebApi.newsletter(values.email)
                    .then((response) => {
                      actions.resetForm();
                      actions.setStatus({ success: true });
                    })
                    .catch(({ response }) => {
                      actions.resetForm();
                      actions.setStatus({
                        error: true,
                        errorMessage: response.data.message,
                      });
                    });
                }}
                validationSchema={schema}
              >
                {({ touched, errors, isSubmitting, handleSubmit, status }) => (
                  <>
                    <form className="newsletter-form-1 mt--50 radius-round" onSubmit={handleSubmit}>
                      <Field className="rbt-border" placeholder="Digite seu e-mail" name="email" />
                      <button type="submit" disabled={isSubmitting} className="rbt-btn btn-secondary radius-round">
                        Cadastrar
                      </button>
                    </form>
                    <ErrorMessage component="div" name="email" />
                    {status && status.success && (
                      <p>
                        <small>Email cadastrado com sucesso, verifique sua caixa de entrada para confirmar.</small>
                      </p>
                    )}
                    {status && status.error && (
                      <p>
                        <small className="color-danger"> {status.errorMessage}</small>
                      </p>
                    )}
                  </>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;

