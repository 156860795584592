import Link from "next/link";

type Props = {};

const Navigation: React.FC<Props> = (props) => {
  return (
    <nav className="mainmenu-nav">
      <ul className="mainmenu">
        <li className="with-megamenu has-menu-child-item text-uppercase">
          <Link href="/temporario">Home</Link>
        </li>
        <li className="with-megamenu has-menu-child-item text-uppercase">
          <Link href="/blog">Notícias</Link>
        </li>
        <li className="with-megamenu has-menu-child-item text-uppercase">
          <Link href="/cursos">Cursos</Link>
        </li>
        <li className="with-megamenu has-menu-child-item text-uppercase">
          <Link href="/institucional">Institucional</Link>
        </li>
        <li className="with-megamenu has-menu-child-item text-uppercase">
          <Link href="/contato">Contato</Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;

